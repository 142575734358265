import React, { useRef, useState } from "react"
import Layout from "../../components/layout"
import emailjs from "@emailjs/browser"
import { FaCheckCircle } from "react-icons/fa"
import { Helmet } from "react-helmet"
import Seo from "../../components/seo"

const Contact = () => {
  const form = useRef()

  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    message: "",
  })
  const [errorStatus, setErrorStatus] = useState({
    name: false,
    email: false,
    message: false,
  })
  const [sendStatus, setSendStatus] = useState(null)

  const sendEmail = e => {
    e.preventDefault()
    console.log(formValues)
    if (
      formValues.name !== "" &&
      formValues.email !== "" &&
      formValues.message !== ""
    ) {
      emailjs
        .sendForm(
          "service_cm6n7kq",
          "template_n7i4hlj",
          form.current,
          "user_xIGrxC23Zd2FKNrlB0Ika"
        )
        .then(
          result => {
            // console.log(result.text)
            if (result.text === "OK") {
              setFormValues({
                name: "",
                email: "",
                message: "",
              })
              setErrorStatus({
                name: false,
                email: false,
                message: false,
              })
              setSendStatus(true)
            }
          },
          error => {
            console.log(error.text)
            setFormValues({
              name: "",
              email: "",
              message: "",
            })
            setErrorStatus({
              name: false,
              email: false,
              message: false,
            })
            setSendStatus(false)
          }
        )
    } else {
      let validEmailRegex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}")
      setErrorStatus({
        name: formValues.name === "",
        email: !validEmailRegex.test(formValues.email),
        message: formValues.message === "",
      })
      console.log(errorStatus)
    }
  }

  return (
    <Layout>
      <Seo title="Contact Us"></Seo>

      {/* <Helmet>
        <title>Contact Us</title>
      </Helmet> */}
      <form ref={form} onSubmit={sendEmail}>
        <div className="flex justify-center items-center w-full h-[100vh] ">
          <div className="w-1/4 sm:w-full sm:p-8">
            <div className="relative">
              <div className="text-[#1F2626] dark:text-[#BDEBEA]">Name</div>
              <input
                id="from_name"
                name="from_name"
                className="w-full text-[#1F2626] dark:text-[#BDEBEA] border-[#534084] dark:border-[#BDEBEA] focus-visible:border-[#5222D0] dark:focus-visible:border-[#00D9F5] border-2 rounded-md px-2 my-2"
                type="text"
                value={formValues.name}
                onChange={e => {
                  setFormValues({ ...formValues, name: e.target.value })
                }}
              />
              {errorStatus.name && (
                <div className="relative  text-xs text-[#C05353]">
                  <p className="float-right font-semibold">Name is required</p>
                </div>
              )}
            </div>
            <div className="relative">
              <div className="text-[#1F2626] dark:text-[#BDEBEA]">Email</div>
              <input
                id="email"
                name="email"
                className="w-full text-[#1F2626] dark:text-[#BDEBEA] border-[#534084] dark:border-[#BDEBEA] focus-visible:border-[#5222D0] dark:focus-visible:border-[#00D9F5] border-2 rounded-md px-2 my-2"
                type="text"
                value={formValues.email}
                onChange={e => {
                  setFormValues({ ...formValues, email: e.target.value })
                }}
              />
              {errorStatus.email && (
                <div className="relative  text-xs text-[#C05353]">
                  <p className="float-right font-semibold">
                    Valid Email address is required
                  </p>
                </div>
              )}
            </div>
            <div className="relative">
              <div className="text-[#1F2626] dark:text-[#BDEBEA]">Message</div>
              <textarea
                id="message"
                name="message"
                className="w-full text-[#1F2626] dark:text-[#BDEBEA] h-36 border-[#534084] dark:border-[#BDEBEA] focus-visible:border-[#5222D0] dark:focus-visible:border-[#00D9F5] border-2 rounded-md px-2 my-2"
                type="text"
                value={formValues.message}
                onChange={e => {
                  setFormValues({ ...formValues, message: e.target.value })
                }}
              />
              {errorStatus.message && (
                <div className="relative  text-xs text-[#C05353]">
                  <p className="float-right font-semibold">
                    Message is required
                  </p>
                </div>
              )}
            </div>
            <div>
              <button className="p-4 py-2 bg-[#5222D0] dark:bg-darkHeading rounded-md text-[#DBFFFF] dark:text-[#1F2626] my-2">
                Submit
              </button>
            </div>
            <div>
              {sendStatus === true && (
                <p className="text-green-600 ">
                  <FaCheckCircle className="inline-block" /> Message sent
                  successfully
                </p>
              )}
              {sendStatus === false && (
                <p className="text-[#C05353]">Failed. something went wrong</p>
              )}
            </div>
          </div>
        </div>
      </form>
    </Layout>
  )
}

export default Contact
